import Vue from 'vue';
import App from './App.vue';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import store from './store';
import router from './routes/router';
import { commonHelper } from './common-helper';
import axios from 'axios';
import { ApiManager } from './api-manager';
import VueI18n from 'vue-i18n';

//axios
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV == 'production' ? 'https://api.holascreen.com/' : 'http://localhost:3001/',
  headers: {
    'Content-Type': 'application/json'
  }
});

//globally
window.$axios = axiosInstance;
window.$commonHelper = commonHelper;
Vue.prototype.$api = new ApiManager();
Vue.prototype.$commonHelper = commonHelper;

Vue.use(VueMaterial);
Vue.use(VueI18n);
Vue.config.productionTip = false;

//localization
const i18n = new VueI18n({
  locale: 'tr',
  messages: { tr: {} }
});

window.$i18n = i18n;


(async () => {
  await commonHelper.setLanguages();
  await commonHelper.setLocalizations();
  new Vue({
    vuetify,
    store,
    render: h => h(App),
    router,
    i18n
  }).$mount('#app')
})();