import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    setLoading: false,
    alert: {
      show: false,
      message: '',
      type: ''
    },
    localization: {},
    languages: []
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations
})