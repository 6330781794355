
import store from './store';

class CommonHelper {
    constructor() { }

    showAlert(type, message) {
        store.commit('set', ['alert', { type: type, message: message, show: true }]);
        setTimeout(() => {
            store.commit('set', ['alert', { show: false }]);
        }, 5000);
    }

    showLoading() {
        store.commit('set', ['setLoading', true]);
    }

    hideLoading() {
        store.commit('set', ['setLoading', false]);
    }

    handleError(error) {
        console.log(error);
        commonHelper.hideLoading();

        return Promise.resolve();
    }

    getCurrentLanguage() {
        return localStorage.getItem('lang') || 'tr';
    }

    async setLanguages() {
        commonHelper.showLoading();
        await window.$axios.get('common/languages').then(function (response) {
            commonHelper.hideLoading();
            if (response && response.data) {
                store.commit('set', ['languages', response.data]);
            }
        });
    }

    async setLocalizations(language) {
        const lang = language || localStorage.getItem('lang') || 'tr';
        localStorage.setItem('lang', lang);
        commonHelper.showLoading();
        await window.$axios.get(`common/localizations?type=website&language=${lang}`).then(function (response) {
            commonHelper.hideLoading();
            if (response && response.data) {
                store.commit('set', ['localization', response.data]);
                window.$i18n.setLocaleMessage(lang, response.data[lang]);
                window.$i18n.locale = lang;
            }
        }).catch(this.handleError)
    }
}

export let commonHelper = new CommonHelper();