import MainLayout from '@/layouts/MainLayout.vue';

const routes = [
  {
    path: '/',
    redirect: 'main',
    component: MainLayout,
    children: [
      {
        path: '/main',
        name: 'main',
        component: () => import('../views/Main.vue')
      },
      {
        path: '/privacy-and-security',
        name: 'privacy-and-security',
        component: () => import('../views/PrivacyAndSecurity.vue')
      },
      {
        path: '/delivery-and-return-notification',
        name: 'delivery-and-return-notification',
        component: () => import('../views/DeliveryAndReturnNotification.vue')
      },
      {
        path: '/sales-contract',
        name: 'sales-contract',
        component: () => import('../views/SalesContract.vue')
      }
    ]
  },
  { path: '*', component: () => import('../views/NotFoundPage.vue') }
];

export default routes;
