import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#263C6F',
            secondary: '#3fa7dd'
          },
          dark: {
            primary: '#263C6F',
            secondary: '#3fa7dd'
          },
        },
      }
});
